export default function(state,action){
  ////console.log("user states")
  if(!state&&!action.payload){
      return  null
  }
    switch(action.type){
        case 'IS_USER':
            return action.payload;
        case 'IS_UPDATE_USER':
            state[action.payload[0]]=action.payload[1];
            return {...state, user: state};
        default:
            return state;
    }
}
