import React from 'react';
import ReactDOM from 'react-dom';
import { createStore,applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import reducers from './reducers'
import ReduxPromise from 'redux-promise'
import * as serviceWorker from './serviceWorker';
import './index.css';


import App from './App';


const createStoreWithMiddleware= applyMiddleware(ReduxPromise)(createStore);

ReactDOM.render(<Provider store={createStoreWithMiddleware(reducers)}><App /></Provider>, document.getElementById('root'));
//registerServiceWorker();

serviceWorker.unregister();
