import React, {Component} from 'react';
import {connect} from 'react-redux';
import HTMLFlipBook from "react-pageflip";
import {bindActionCreators} from 'redux';
import { withRouter } from 'react-router';
import LazyLoad from 'react-lazy-load';
import axios from 'axios';
import {setUser,isRegister,translate,blink,getSetLanguage} from '../actions/index';
import '../index.css';
import queryString from "query-string";
const PageCover = React.forwardRef((props, ref) => {
    return (
      <div className="page page-cover" ref={ref} data-density="hard">
        <div className="page-content">
          {!props.end&&<h2>{props.children}</h2>}
          {props.end&&<div>{props.children}</div>}
        </div>
      </div>
    );
  });

  const Page = React.forwardRef((props, ref) => {
    return (
      <div className="page" ref={ref}>
        <div className="page-content">
          <h2 className="page-header">Page header - {props.number}</h2>
          <div className="page-image"></div>
          <div className="page-text">{props.children}</div>
          <div className="page-footer">{props.number + 1}</div>
        </div>
      </div>
    );
  });

class Game extends Component{


    componentWilUnmount(){
     document.removeEventListener("keyup", this.keydown);
    }
    keydown(evt){

const params = queryString.parse(this.props.history.location.search);


         if(params.skus){
         console.log(evt);

         if(evt.key=='ArrowLeft'||evt.key==','){
         this.flipBook.pageFlip().flipNext()



         }

 if(evt.key=='ArrowRight'||evt.key=='.'){
         this.flipBook.pageFlip().flipPrev()



         }
         }

  console.log(evt)

    }
    getSlide(){
const params = queryString.parse(this.props.history.location.search);

    if(this.props.register.skus){



    return this.props.register.skus.map((item,index)=>{

    if(!this.props.register.skus[index]){

     return <PageCover  key={index}  end={true}> <a target="_blank" href={`https://bluefurn.com`}><img src="https://eamesstoelen.nl/newsletter/logo-bluefurn-white.png" width={200}/></a>
                    <img src="https://eamesstoelen.nl/newsletter/kiyoh-white.png" width="100" />
                </PageCover>
    }
    return(

      <div className={`dd-page page ${params['class']?params['class']:''}`} key={index} key={index} number={index+1}>
       {index<=1&&<img  width="420" src={`https://eamesstoelen.nl/generated/${item}_image${params['prefix']?params['prefix']:''}.png`} alt={`${item}`} className={'flipimage'}/>}
       {index>1&&<LazyLoad onError={()=>{

       this.src=`https://eamesstoelen.nl/generated/${this.props.register.skus[1]}_image.png`
       }} height={762}><img width="420" src={`https://eamesstoelen.nl/generated/${item}_image${params['prefix']?params['prefix']:''}.png`} alt={`${item}`} className={'flipimage'}/></LazyLoad>}
        <a target="_blank" href={`https://eamesstoelen.nl/generated/${item}.html`} target="_blank"><div className="buynow">Buy</div></a>
         <div className="page-footer">{index + 1}</div>
        </div>

      )


    })

    }

return null;

    }
    constructor(props) {
      super(props);
this.flipBook=React.createRef();;
      this.state = {
        page: 0,
        totalPage: 0,
      };
    }

    nextButtonClick = () => {


      this.flipBook.pageFlip().flipNext();
    };

    prevButtonClick = () => {
      this.flipBook.pageFlip().flipPrev();
    };

    onPage = (e) => {
      this.setState({
        page: e.data,
      });
    };

    componentDidMount() {


    document.addEventListener("keyup", this.keydown.bind(this));
const params = queryString.parse(this.props.history.location.search);


  if(params.skus){
              var skus=params.skus.split(",");

              this.loadSlides(skus)

        }else{
                this.getSkus()

        }



    }
    getSkus(){
const me=this;
axios.get('/generated/skus.txt', {

  })
  .then(function (response) {
    ;
    me.loadSlides(response.data.split(","))

  })


    }
    loadSlides(skus){

console.log(skus)
        const params = queryString.parse(this.props.history.location.search);


        if(params.random&&params.random=='true'){
                  for (let i = skus.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                const temp = skus[i];
                skus[i] = skus[j];
                skus[j] = temp;
              }
             }
  var skus_=[];
  var skus_obj={}
   for (var x=0;x<skus.length;x++) {
    if(skus[x]&&skus[x].trim()!=''&&!skus_obj[skus[x]]){
skus_obj[skus[x]]=1;
    skus_.push(skus[x])
    }


   }
   skus=skus_;
        this.setState({
        totalPage: skus.length,
      });

       this.props.isRegister(['skus',skus]);







    }
    bookCover(){

    const params = queryString.parse(this.props.history.location.search);


        if(params.cover_start){

         return <PageCover><div className="coverImage">

       <img width="420" src={`${params.cover_start}`} alt={`Bluefurn`} className={'flipimage'}/>

        </div>

        </PageCover>
        }




    }
    getFirstPage(){
const params = queryString.parse(this.props.history.location.search);

        if(params.cover_start){
        return this.bookCover();
        }

    return <PageCover><img src="https://eamesstoelen.nl/newsletter/img/catalog.png" width={50}/><br/><img src="https://eamesstoelen.nl/newsletter/logo-bluefurn-white.png" width={200}/></PageCover>

    }
     getLastPage(){
const params = queryString.parse(this.props.history.location.search);




    return <PageCover end={true}> <a target="_blank" href={`https://bluefurn.com`}><img src="https://eamesstoelen.nl/newsletter/logo-bluefurn-white.png" width={200}/><div className="end">We have the right product, because we offer quality furniture inspired on famous designers like Charles and Ray Miller, Wegner, Henningson, Mies van der Rohe and Jacobsen. Browse through our collection. All furniture is manufactured according to our high quality requirements. We wish you a lot of pleasure with our beautifull products.</div></a>
                    <img src="https://eamesstoelen.nl/newsletter/kiyoh-white.png" width="100" />
                </PageCover>


    }
    getBook(){
     const params = queryString.parse(this.props.history.location.search);

    if(params.show_cover&&params.show_cover=='true'){

    return(
          <HTMLFlipBook
             width={params['width']?params['width']:420}
            height={params['height']?params['height']:320}
            size="stretch"
            minWidth={params['minWidth']?params['minWidth']:220}
            maxWidth={params['maxWidth']?params['maxWidth']:500}
            minHeight={params['minHeight']?params['minHeight']:150}
            maxHeight={params['maxHeight']?params['maxHeight']:1000}
            maxShadowOpacity={0.5}
            mobileScrollSupport={true}
            onFlip={this.onPage}
            showCover={false}
            onChangeOrientation={this.onChangeOrientation}
            onChangeState={this.onChangeState}
            className="demo-book"
             ref={(el) => (this.flipBook = el)}
          >
          {this.getFirstPage()}
          {this.getSlide()}
          {this.getLastPage()}
          </HTMLFlipBook>)
    }
    return (<HTMLFlipBook
            width={params['width']?params['width']:420}
            height={params['height']?params['height']:320}
            size="stretch"
            minWidth={params['minWidth']?params['minWidth']:220}
            maxWidth={params['maxWidth']?params['maxWidth']:500}
            minHeight={params['minHeight']?params['minHeight']:150}
            maxHeight={params['maxHeight']?params['maxHeight']:1000}
            maxShadowOpacity={0.5}
            mobileScrollSupport={true}
            onFlip={this.onPage}
            showCover={false}
            onChangeOrientation={this.onChangeOrientation}
            onChangeState={this.onChangeState}
            className="demo-book"
             ref={(el) => (this.flipBook = el)}
          >

          {this.getSlide()}


          </HTMLFlipBook>)

    }
    render() {
    if(!this.props.register.skus)return null;
      return (
      <div className="pageholder">
        {this.getBook()}

          <div className="container">
            <div className="footerBar">
              <button type="button" onClick={this.prevButtonClick}>
                {'<'}
              </button>

              <div className="numHolder"><div>{this.state.page}/{this.state.totalPage}</div>
</div>
              <button type="button" onClick={this.nextButtonClick}>
                {'>'}
              </button>

            </div>

          </div>
        </div>
      );
    }


}

function mapDispatchToProps(dispatch){
    return bindActionCreators({setUser,isRegister},dispatch);

}
function mapStateToProps(state){
    return{
      user:state.user,
      register:state.register
    }

}
export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Game))
