var langs={
  ar_AR:require('./locale/ar_AR.json'),
  cs_CZ:require('./locale/cs_CZ.json'),
  de_DE:require('./locale/de_DE.json'),
  el_GR:require('./locale/el_GR.json'),
  en_GB:require('./locale/en_GB.json'),
  es_ES:require('./locale/es_ES.json'),
  fr_FR:require('./locale/fr_FR.json'),
  hu_HU:require('./locale/hu_HU.json'),
  it_IT:require('./locale/it_IT.json'),
  ja_JP:require('./locale/ja_JP.json'),
  nl_NL:require('./locale/nl_NL.json'),
  pl_PL:require('./locale/pl_PL.json'),
  pt_PT:require('./locale/pt_PT.json'),
  ru_RU:require('./locale/ru_RU.json'),
  th_TH:require('./locale/th_TH.json'),
  tr_TR:require('./locale/tr_TR.json'),
  vi_VN:require('./locale/vi_VN.json'),
  zh_CN:require('./locale/zh_CN.json')

}
module.exports=langs;
