import axios from 'axios';
import Moment from 'react-moment';
import moment from 'moment';

import {Normalize} from 'smnormalize'
import renderHTML from 'react-render-html';
export const BOOK_SELECTED = 'BOOK_SELECTED';
export const FETCH_WEATHER = 'FETCH_WEATHER';
export const IS_UPLOADING = 'IS_UPLOADING';
export const IS_LANG = 'IS_LANG';
export const IS_USER="IS_USER";
export const IS_LIST_LANG="IS_LIST_LANG";
export const IS_UPDATE_USER ="IS_UPDATE_USER";

var engineStr = require('engine-handlebars');
let langs=require('./languages');
var lib, images, createjs;
var canvas,canvas2,canvasdealer2;
var stage,stage2,stagedealer;
window.langs=[];

(function (lib, img, cjs) {



})(window.lib = window.lib||{}, images = images||{}, createjs = createjs||{});
String.prototype.capitalizeFirstLetter = function() {
	return this.charAt(0).toUpperCase() + this.slice(1);
}
export function interAd(){


 if(!window.bartimeout&&!window.bannerview){

   window.bartimeout=setInterval(()=>{

               if(window.ads_index<3){

               window.ads_index+=1;
               }else{

               window.ads_index=0;
               }
            },1*30000);
            }

            window.adTimeout= setTimeout(()=>{

            if(!window.adTimeoutCounter){
                window.adTimeoutCounter=1
                }else{

                window.adTimeoutCounter+=1;
                }
                if(window.adTimeoutCounter>Math.ceil((Math.random()*3))){
                window.adTimeoutCounter=0;
                 if(window.qrdeliver&&!window.bannerview){
window.bannerview=true;
window.createjs.Sound.stop('intro');
   window.qrdeliver.postMessage(escape(
              `
              {
                "cmd": "interad"
              }
              `));


            }

                }

        },Math.floor(Math.random()*15000))

}
export function formatToUnits(number, precision) {
  const abbrev = ['', 'k', 'm', 'b', 't'];
  const unrangifiedOrder = Math.floor(Math.log10(Math.abs(number)) / 3)
  const order = Math.max(0, Math.min(unrangifiedOrder, abbrev.length -1 ))
  const suffix = abbrev[order];

  return (number / Math.pow(10, order * 3)).toFixed(precision) + suffix;
}
export function replaceString(str,len=50) {
    if (str) {
     return Normalize(str, {removeNumbers:false,keepEmojis:true,mode:"alphabetic",preserveCharacters:' =.,?!/:'})
    }


    return '';
}
export function blink(time, fadeIn) {
	if (!time) {
		time = 100;
	}
	window.$("#holder").css("background-size", '40% 30%')
	window.$("#deco2").css("background-size", '160% auto');
	if (fadeIn) {
		window.$("#holder").delay(time).css("background-size", '100% 90%')
		window.$("#deco2").delay(time).css("background-size", '100% auto')
	}

}
export  function isRegister(data){



    return {type:"IS_REGISTER_UPDATE", payload:data};


}

export  function playSound(scope,sound){


        if(!window.soundCollection){
        window.soundCollection={};


        }

        if(window.soundCollection[sound]){
        return;

        }
    if(scope.props.register&&scope.props.register.wsuser){
			if(scope.props.register&&scope.props.register.wsuser.sound){

						 window.createjs.Sound.play(sound);
						 const ss=sound;
						 window.soundCollection[sound] = setTimeout(()=>{

						     delete window.soundCollection[ss];

						 },5000)
			}
		}


}
export  function stopSound(scope,sound){




						 window.createjs.Sound.stop(sound);



}
export  function loopSound(scope,sound,loop){



    if(scope.props.register&&scope.props.register.wsuser){
			if(scope.props.register&&scope.props.register.wsuser.sound&&scope.props.register.wsuser.sound===true){
						window.createjs.Sound.stop(sound);
						 window.createjs.Sound.play(sound, loop);
			}
		}


}
export  function setUser(user){



    return {type:IS_USER, payload:user};


}
export function translate(k,language) {
	if(!language)return k;
	if (language[k] != null) {
		if ( typeof language[k] != "string") {
			return language[k]
		} else {
			return language[k].capitalizeFirstLetter();
		}
	} else {
		return k;
	}
}
export function scopeTranslate(scope,k) {
	if(!scope.props.register.lang)return k;
	if (scope.props.register.lang[k] != null) {
		if ( typeof scope.props.register.lang[k] != "string") {
			return scope.props.register.lang[k]
		} else {
			return scope.props.register.lang[k].capitalizeFirstLetter();
		}
	} else {
		return k;
	}
}
export function getSetLanguage(scope,userlang,cb){
if(!window.$)return null;
	if(!langs[userlang]){
		userlang='en_GB';

	}
	if(langs[userlang]){

		scope.props.isRegister(['lang',langs[userlang]])
		if(userlang=='ar_AR'||userlang=='zh_CN'){
			window.$('body').removeClass('rtl').addClass('rtl')
		}else{
				window.$('body').removeClass('rtl')
		}
			cb(true)

		return;
	}


}
export function getSetLanguageScreen(scope,userlang,cb){




		if(!langs[userlang]){
			userlang='en_GB';

		}
		if(langs[userlang]){

			scope.props.isRegister(['lang',langs[userlang]])
			if(userlang=='ar_AR'||userlang=='zh_CN'){
				window.$('body').removeClass('rtl').addClass('rtl')
			}else{
					window.$('body').removeClass('rtl')
			}
				cb(true)

			return;
		}


}
